// React imports
import React from 'react';
import LocalizedStrings from 'react-localization';

// App imports
import NavBar from './js_components/navbar';
import Hero from './js_components/hero';
import Calculator from './js_components/calculator';
import Footer from './js_components/footer';

// Data imports
import AppLogoFi1 from './data/mk_logo_vaaka_FI.png';
import AppLogoSv1 from './data/skogscentralen_logo.png';
import TapioLogo from './data/logo-tapio.png';
import SitowiseLogo from './data/logo-sitowise.png';
import JSONMunicipalities2017 from './data/municipalities_2017.json';
import JSONMunicipalities2018 from './data/municipalities_2018.json';
import JSONMunicipalities2019 from './data/municipalities_2019.json';
import JSONMunicipalities2020 from './data/municipalities_2020.json';
import JSONMunicipalities2021 from './data/municipalities_2021.json';
import JSONMunicipalities2022 from './data/municipalities_2022.json';
import JSONMunicipalities2023 from './data/municipalities_2023.json';
import JSONMunicipalities2024 from './data/municipalities_2024.json';
import JSONTaxClasses from './data/taxclasses.json';
import JSONLanguages from './data/languages.json';
import localeFi from './data/localeFi.json';
import localeSv from './data/localeSv.json';

// Style imports
import 'bulma/css/bulma.css';
import 'font-awesome/css/font-awesome.css';
import 'react-select/dist/react-select.css';
import './css/App.css';
import { useState } from 'react';
import { useEffect } from 'react';

// App localization
const locale = new LocalizedStrings({
    fi: localeFi,
    sv: localeSv,
});

const App = () => {
    const [language, setLanguage] = useState('fi');
    const [liveRegionMessage, setLiveRegionMessage] = useState('');

    useEffect(() => {
        document.documentElement.setAttribute('lang', language);
    }, [language]); // The empty array ensures this effect is run only once

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    };

    useEffect(() => {
        locale.setLanguage(language);
    }, [language]);

    function capitalizeFirstLetter(string) {
        return (
            [...string][0].toUpperCase() +
            [...string].slice(1).join('').toLowerCase()
        );
    }

    locale.setLanguage(language);

    // App logo
    var AppLogo = null;

    // Do some i18n before rendering
    if (locale.getLanguage() === 'fi') {
        JSONTaxClasses.forEach(function (taxClass) {
            taxClass.label = taxClass.label_fi;
            AppLogo = AppLogoFi1;
        });
    } else {
        JSONTaxClasses.forEach(function (taxClass) {
            taxClass.label = taxClass.label_sv;
            AppLogo = AppLogoSv1;
        });
    }

    // Webpage title
    document.title =
        capitalizeFirstLetter(locale.heroTitle) + ' - ' + locale.company;

    useEffect(() => {
        // Set the lang attribute of the <html> element
        document.documentElement.setAttribute('lang', 'fi'); // Change 'fi' to your desired language code

        // Optionally, return a cleanup function if needed
        return () => {
            // Reset the lang attribute or perform other cleanup actions
            document.documentElement.setAttribute('lang', 'en'); // Reset to default if needed
        };
    }, []); // Empty dependency array ensures this effect runs once on mount

    return (
        <div id="App">
            <NavBar
                locale={locale}
                logo={AppLogo}
                languages={JSONLanguages}
                handleLanguageChange={handleLanguageChange}
                language={language}
            />

            <div
                role="status"
                aria-live="polite"
                style={{
                    position: 'absolute',
                    width: '1px',
                    height: '1px',
                    margin: '-1px',
                    border: '0',
                    padding: '0',
                    clip: 'rect(0 0 0 0)',
                    overflow: 'hidden',
                }}
            >
                {liveRegionMessage}
            </div>

            <Hero locale={locale} language={language} />

            <Calculator
                locale={locale}
                logo={AppLogo}
                logoTapio={TapioLogo}
                logoSitowise={SitowiseLogo}
                municipalities2017={JSONMunicipalities2017}
                municipalities2018={JSONMunicipalities2018}
                municipalities2019={JSONMunicipalities2019}
                municipalities2020={JSONMunicipalities2020}
                municipalities2021={JSONMunicipalities2021}
                municipalities2022={JSONMunicipalities2022}
                municipalities2023={JSONMunicipalities2023}
                municipalities2024={JSONMunicipalities2024}
                taxClasses={JSONTaxClasses}
                setLiveRegionMessage={setLiveRegionMessage}
            />

            <Footer
                locale={locale}
                logo={AppLogo}
                logoTapio={TapioLogo}
                logoSitowise={SitowiseLogo}
            />
        </div>
    );
};

export default App;
