// React imports
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';

// 3rd Party imports
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

// App imports
import Utils from './utils';
import CalcField from './calcfield';
import ToolTip from './tooltip';

const maxNumberLength = 10;

// Calculator initial state
const initialState = {
    year: {
        selectedYear: '-',
    },
    estates: [
        {
            description: '',
            municipality: '',
            profitNum: '',
            area: '',
            validatedArea: true,
            validatedMun: true,
        },
    ],

    calculation: {
        giftEstateAreaSummary: 0, // blue , D20
        estateAreaSummary: 0, // white, D21
        taxMultiplier: 10, // white, D22
        forestryPropertyTaxed: 0, // white, D23
        calculatorAreaThreshold: 100, // const
        areaOverThreshold: 0, // white, D24, ratio
        normalThreshold: 30000, // white, D25
        calculatorThreshold: 30000, // white, D26 <-- NOT USED ANYMORE
        forestryPropertyTaxedOverThreshold: 0, // white, D27, ratio
        overThresholdOrOverAreaThreshold: 0, // white, D28, ratio
        taxClass: 1, // yello, D29, int (1 or 2)
        currentPrice: '', // yello, D30, euros
        giftTax: 0, // red  , D31, euros
        giftTaxEdit: false, // EDIT Mode for USER
        taxerMultiplier: 2.4, // white, D32, percentage
        forestryGiftCut: 0, // blue , D33, euros
        forestryGiftCutMultiplier: 2, // white, D34
        investmentIncome: 0, // blue , D35, euros
        investmentIncomeTax: 30, // yello, D36, percentage
        netProfit: 0, // blue , D37, euros
    },
    extraCalculation: {
        amountOfGifts: 1, // yello, D39, n
        tradePercentage: 0, // yello, D40, percentage
        tradePrice: 0, // blue , D41, euros
        giftTaxAmount: 0, // blue , D43, euros
        tradeArea: 0, // blue , D44, hectares
        tradeOtherCosts: 0, // yello, D45, euros
        giftForestryCut: 0, // yello, D46, euros
        leftOverForestryCut: 0, // yello, D47, euros
        overallForestryCut: 0, // red  , D48, euros
        overallForestryCutEdit: false, // EDIT Mode for USER
    },
    giftTaxCalculation: {
        minBound: 0, // Calculated parameter
        taxAtMinBound: 0, // Calculated parameter
        taxOfOverBoundsPart: 0, // Calculated parameter
        giftTax: 0, // red  , D31, euros, Calculated parameter
    },
    calculationResults: {
        oneGiftCurrentPrice: 0, // white, D49, euros
        tradeForestryCut: 0, // blue , D50, euros
        giftForestryCutNetProfit: 0, // blue , D51, euros
        fInvestmentIncomeToUtilizeForestryCut: 0, // blue , D52, euros
    },
    resultsStr: {
        currentPrice: '0 €',
        investmentIncomeTax: '0 %',
        amountOfGifts: '0 kpl',
        tradePercentage: '0 %',
        tradeOtherCosts: '0 €',
        giftForestryCut: '0 €',
        leftOverForestryCut: '0 €',
        giftTax: '0 €',
        forestryGiftCut: '0 €',
        investmentIncome: '0 €',
        netProfit: '0 €',
        tradePrice: '0 €',
        giftTaxAmount: '0 €',
        tradeArea: '0 ha',
        overallForestryCut: '0 €',
        tradeForestryCut: '0 €',
        giftForestryCutNetProfit: '0 €',
        fInvestmentIncomeToUtilizeForestryCut: '0 €',
    },
    validation: {
        year: true,
        estateMunicipalities: true,
        estateAreas: true,
        currentPrice: true,
        investmentIncomeTax: true,
        amountOfGifts: true,
        tradePercentage: true,
        validated: false,
    },
};

// Component class
class Calculator extends Component {
    constructor(props) {
        super(props);
        this.state = { ...initialState };
    }

    componentDidMount() {
        // Select library doesn't seem to allow set required attribute for dropdowns list
        // Force the required attribute for the dropdown list
        // This is required for accessibility reasons
        const selectYearInput = document.getElementById('select-year');
        if (selectYearInput) {
            selectYearInput.setAttribute('required', 'required');
        }
    }

    // ------------------------------------------------------------------------
    // -- Event: re-initialize the component with initial data
    // ------------------------------------------------------------------------
    handleClear(event) {
        let state = { ...initialState };
        state.year = {
            selectedYear: '-',
        };
        // State is still being mutated, I don't know if it's a bug, manual fix for now...
        state.estates = [
            {
                description: '',
                municipality: '',
                profitNum: '',
                area: '',
                validatedArea: true,
                validatedMun: true,
            },
        ];

        // CalcField doesn't work perfectly, have to do this...
        this.refs.currentprice.state.value = '';
        this.refs.investmentincometax.state.value = 30;
        this.refs.amountofgifts.state.value = 1;
        this.refs.tradepercentage.state.value = 0;
        this.refs.tradeothercosts.state.value = 0;
        this.refs.giftforestrycut.state.value = 0;
        this.refs.leftoverforestrycut.state.value = 0;
        this.refs.checkBox1.checked = false;
        this.refs.checkBox2.checked = false;

        // Just make sure validation is false
        state.validation.validated = false;

        this.setState(state);

        this.props.setLiveRegionMessage(this.props.locale.ariaCalcResultsEmpty);
    }

    // ------------------------------------------------------------------------
    // -- Event: Append row to estates grid
    // ------------------------------------------------------------------------
    handleAddRowToEstateGrid(event) {
        let estates = [...this.state.estates];

        estates.push({
            description: '',
            municipality: '',
            profitNum: '',
            area: '',
            validatedArea: true,
            validatedMun: true,
        });

        this.setState({
            estates: estates,
        });

        this.props.setLiveRegionMessage(this.props.locale.ariaRowAdded);
    }

    // ------------------------------------------------------------------------
    // -- Event: Remove row from estates grid
    // ------------------------------------------------------------------------
    handleRemRowFromEstateGrid(event) {
        let target = event.target;
        let index = target.name;

        let estates = [...this.state.estates];
        let calc = { ...this.state.calculation };
        let calcExtra = { ...this.state.extraCalculation };

        calc.estateAreaSummary -= estates[index].area;
        calc.giftEstateAreaSummary =
            ((calc.estateAreaSummary / calcExtra.amountOfGifts) *
                (100.0 - calcExtra.tradePercentage)) /
            100.0;

        calc.estateAreaSummary = Utils.roundToTwoDec(calc.estateAreaSummary);
        calc.giftEstateAreaSummary = Utils.roundToTwoDec(
            calc.giftEstateAreaSummary
        );

        estates.splice(index, 1);

        this.setState({
            estates: estates,
            calculation: calc,
        });

        this.props.setLiveRegionMessage(this.props.locale.ariaRowDeleted);
    }

    // ------------------------------------------------------------------------
    // -- Event: Update specific estate grid row description
    // ------------------------------------------------------------------------
    handleEstateDescChanged(event) {
        let target = event.target;
        let index = target.name;
        let value = target.type === 'checkbox' ? target.checked : target.value;

        let estates = [...this.state.estates];
        estates[index].description = value;

        this.setState({
            estates: estates,
        });
    }

    // ------------------------------------------------------------------------
    // -- Event: Update specific estate grid row area
    // ------------------------------------------------------------------------
    handleEstateAreaChanged(event) {
        let index = event.target.name;
        let value = event.target.value;

        if (value.length > maxNumberLength) {
            value = value.slice(0, maxNumberLength);
        }

        let estates = [...this.state.estates];
        let calc = { ...this.state.calculation };
        let calcExtra = { ...this.state.extraCalculation };

        // Replace , with . if number field
        value = value.replace(/,/g, '.');

        // If input does not satisfy for actual assignment, return
        if (Utils.isValidInputNumber(value) === false) {
            return;
        }

        // If input is valid numeric value, proceed
        if (Utils.isValidInputNumber(value) === true) {
            if (Utils.isNumeric(value)) value = Utils.roundToOneDec(value);

            estates[index].area = value;

            calc.estateAreaSummary = 0.0;
            estates.map(function (row, index) {
                if (row.area) calc.estateAreaSummary += row.area;
                return 0;
            });

            // Calculate whole gift area summary
            calc.giftEstateAreaSummary =
                ((calc.estateAreaSummary / calcExtra.amountOfGifts) *
                    (100.0 - calcExtra.tradePercentage)) /
                100.0;

            // Round numbers to two decimals
            calc.estateAreaSummary = Utils.roundToTwoDec(
                calc.estateAreaSummary
            );
            calc.giftEstateAreaSummary = Utils.roundToTwoDec(
                calc.giftEstateAreaSummary
            );
        }

        // Update state
        this.setState({
            estates: estates,
            calculation: calc,
        });
    }

    // ------------------------------------------------------------------------
    // -- Event: calculate all results
    // ------------------------------------------------------------------------
    handleCalculateResults(event) {
        let estates = [...this.state.estates];
        let calc = { ...this.state.calculation };
        let calcExtra = { ...this.state.extraCalculation };
        let calcResults = { ...this.state.calculationResults };
        let giftTaxCalc = { ...this.state.giftTaxCalculation };
        let resultsStr = { ...this.state.resultsStr };
        let validation = { ...this.state.validation };
        // Form validation
        validation.year = true;
        validation.estateMunicipalities = true;
        validation.estateAreas = true;
        validation.currentPrice = true;
        validation.investmentIncomeTax = true;
        validation.amountOfGifts = true;
        validation.tradePercentage = true;
        validation.validated = true;
        if (this.state.year.selectedYear === '-') {
            validation.year = false;
        }

        estates.map(function (row, index) {
            if (
                !row.municipality ||
                (!row.profitNum && row.profitNum !== 0.0)
            ) {
                row.validatedMun = false;
                validation.estateMunicipalities = false;
            } else {
                row.validatedMun = true;
            }

            if (!row.area) {
                row.validatedArea = false;
                validation.estateAreas = false;
            } else {
                row.validatedArea = true;
            }

            return 0;
        });

        validation.currentPrice = !Utils.validateNum(calc.currentPrice);
        validation.investmentIncomeTax = !Utils.validateNum(
            calc.investmentIncomeTax
        );
        validation.amountOfGifts = !Utils.validateNum(calcExtra.amountOfGifts);
        validation.tradePercentage = !Utils.validateNum(
            calcExtra.tradePercentage
        );

        if (
            !validation.year ||
            !validation.estateMunicipalities ||
            !validation.estateAreas ||
            !validation.currentPrice ||
            !validation.investmentIncomeTax ||
            !validation.amountOfGifts ||
            !validation.tradePercentage
        ) {
            validation.validated = false;
        }

        this.setState({
            estates: estates,
            validation: validation,
        });

        if (!validation.validated) {
            this.props.setLiveRegionMessage(
                this.props.locale.ariaRequiredFieldNotFilled
            );
            return;
        }

        // Reset calculation status
        calc.forestryPropertyTaxed = 0.0;

        if (Utils.validateNum(calcExtra.tradeOtherCosts)) {
            calcExtra.tradeOtherCosts = 0.0;
        }

        if (Utils.validateNum(calcExtra.giftForestryCut)) {
            calcExtra.giftForestryCut = 0.0;
        }

        if (Utils.validateNum(calcExtra.leftOverForestryCut)) {
            calcExtra.leftOverForestryCut = 0.0;
        }

        // Calculate new results, iterate through each estate
        estates.map(function (row, index) {
            // D23
            calc.forestryPropertyTaxed += row.area * row.profitNum;
            return 0;
        });

        // D23
        calc.forestryPropertyTaxed *= calc.taxMultiplier;
        calc.forestryPropertyTaxed /= calcExtra.amountOfGifts;
        calc.forestryPropertyTaxed *= (100.0 - calcExtra.tradePercentage) / 100;

        // D24
        // NEW D24
        calc.areaOverThreshold =
            (calc.giftEstateAreaSummary - 100.0) / calc.giftEstateAreaSummary;

        // D27
        calc.forestryPropertyTaxedOverThreshold =
            (calc.forestryPropertyTaxed - calc.normalThreshold) /
            calc.forestryPropertyTaxed;

        // D28
        if (calc.forestryPropertyTaxedOverThreshold > calc.areaOverThreshold) {
            calc.overThresholdOrOverAreaThreshold =
                calc.forestryPropertyTaxedOverThreshold;
        } else {
            calc.overThresholdOrOverAreaThreshold = calc.areaOverThreshold;
        }

        // D49, one gift current price
        calcResults.oneGiftCurrentPrice =
            ((calc.currentPrice / calcExtra.amountOfGifts) *
                (100.0 - calcExtra.tradePercentage)) /
            100.0;

        // Bounds check of taxed part of gift in euros
        let value = calcResults.oneGiftCurrentPrice;
        if (calc.taxClass === 1) {
            if (value >= 5000 && value < 25000) {
                giftTaxCalc.minBound = 5000;
                giftTaxCalc.taxAtMinBound = 100;
                giftTaxCalc.taxOfOverBoundsPart = 0.08;
            } else if (value >= 25000 && value < 55000) {
                giftTaxCalc.minBound = 25000;
                giftTaxCalc.taxAtMinBound = 1700;
                giftTaxCalc.taxOfOverBoundsPart = 0.1;
            } else if (value >= 55000 && value < 200000) {
                giftTaxCalc.minBound = 55000;
                giftTaxCalc.taxAtMinBound = 4700;
                giftTaxCalc.taxOfOverBoundsPart = 0.12;
            } else if (value >= 200000 && value < 1000000) {
                giftTaxCalc.minBound = 200000;
                giftTaxCalc.taxAtMinBound = 22100;
                giftTaxCalc.taxOfOverBoundsPart = 0.15;
            } else if (value >= 1000000) {
                giftTaxCalc.minBound = 1000000;
                giftTaxCalc.taxAtMinBound = 142100;
                giftTaxCalc.taxOfOverBoundsPart = 0.17;
            }
        } else if (calc.taxClass === 2) {
            if (value >= 5000 && value < 25000) {
                giftTaxCalc.minBound = 5000;
                giftTaxCalc.taxAtMinBound = 100;
                giftTaxCalc.taxOfOverBoundsPart = 0.19;
            } else if (value >= 25000 && value < 55000) {
                giftTaxCalc.minBound = 25000;
                giftTaxCalc.taxAtMinBound = 3900;
                giftTaxCalc.taxOfOverBoundsPart = 0.25;
            } else if (value >= 55000 && value < 200000) {
                giftTaxCalc.minBound = 55000;
                giftTaxCalc.taxAtMinBound = 11400;
                giftTaxCalc.taxOfOverBoundsPart = 0.29;
            } else if (value >= 200000 && value < 1000000) {
                giftTaxCalc.minBound = 200000;
                giftTaxCalc.taxAtMinBound = 53450;
                giftTaxCalc.taxOfOverBoundsPart = 0.31;
            } else if (value >= 1000000) {
                giftTaxCalc.minBound = 1000000;
                giftTaxCalc.taxAtMinBound = 301450;
                giftTaxCalc.taxOfOverBoundsPart = 0.33;
            }
        }

        // D31, Calculate giftTax in non USER edit mode
        if (calc.giftTaxEdit === false) {
            if (calcExtra.tradePercentage <= 75.0) {
                giftTaxCalc.giftTax = Math.max(
                    (value - giftTaxCalc.minBound) *
                    giftTaxCalc.taxOfOverBoundsPart +
                    giftTaxCalc.taxAtMinBound,
                    0.0
                );
                calc.giftTax = Utils.roundToTwoDec(giftTaxCalc.giftTax);
            } else {
                giftTaxCalc.giftTax = 0.0;
                calc.giftTax = 0.0;
            }
        }

        // D33, ForestryGiftCut
        if (calc.overThresholdOrOverAreaThreshold > calc.areaOverThreshold) {
            calc.forestryGiftCut =
                calc.overThresholdOrOverAreaThreshold *
                calc.giftTax *
                calc.taxerMultiplier;
        } else {
            calc.forestryGiftCut =
                calc.areaOverThreshold * calc.giftTax * calc.taxerMultiplier;
        }
        calc.forestryGiftCut = Utils.roundToTwoDec(calc.forestryGiftCut);
        calc.forestryGiftCut =
            calc.forestryGiftCut >= 0.0 ? calc.forestryGiftCut : 0.0;

        // D35, InvestmentIncome
        calc.investmentIncome =
            calc.forestryGiftCut * calc.forestryGiftCutMultiplier;
        calc.investmentIncome = Utils.roundToTwoDec(calc.investmentIncome);

        // D37, NetProfit
        if (calc.forestryGiftCut >= 1500.0) {
            calc.netProfit =
                (calc.forestryGiftCut * calc.investmentIncomeTax) / 100.0;
            calc.netProfit = Utils.roundToTwoDec(calc.netProfit);
        } else {
            calc.netProfit = 0.0;
        }

        // D41, TradePrice
        calcExtra.tradePrice =
            (calcExtra.tradePercentage / 100.0) *
            (calc.currentPrice / calcExtra.amountOfGifts);
        calcExtra.tradePrice = Utils.roundToTwoDec(calcExtra.tradePrice);

        // D43, GiftTaxAmount
        calcExtra.giftTaxAmount =
            calcExtra.tradePrice *
            (this.state.year.selectedYear < 2024 ? 0.04 : 0.03);
        calcExtra.giftTaxAmount = Utils.roundToTwoDec(calcExtra.giftTaxAmount);

        // D44, TradeArea
        calcExtra.tradeArea =
            ((calc.estateAreaSummary / calcExtra.amountOfGifts) *
                calcExtra.tradePercentage) /
            100.0;
        calcExtra.tradeArea = Utils.roundToTwoDec(calcExtra.tradeArea);

        // D48, Calculate overallForestryCut in non USER edit mode

        let overallForestryCutFORtradeForestryCut =
            calcExtra.overallForestryCut;

        if (calcExtra.overallForestryCutEdit === false) {
            calcExtra.overallForestryCut =
                0.6 *
                (calcExtra.tradePrice +
                    calcExtra.giftTaxAmount +
                    calcExtra.tradeOtherCosts) +
                calcExtra.leftOverForestryCut +
                (calcExtra.tradePercentage <= 75
                    ? (1 - calcExtra.tradePercentage / 100.0) *
                    calcExtra.giftForestryCut
                    : 0);
            calcExtra.overallForestryCut = Utils.roundToTwoDec(
                calcExtra.overallForestryCut
            );
            overallForestryCutFORtradeForestryCut = Utils.roundToTwoDec(
                0.6 *
                (calcExtra.tradePrice +
                    calcExtra.giftTaxAmount +
                    calcExtra.tradeOtherCosts) +
                calcExtra.leftOverForestryCut +
                calcExtra.giftForestryCut
            );
        }
        // D50, TradeForestryCut

        calcResults.tradeForestryCut =
            overallForestryCutFORtradeForestryCut -
            calcExtra.giftForestryCut -
            calcExtra.leftOverForestryCut;
        calcResults.tradeForestryCut = Utils.roundToTwoDec(
            calcResults.tradeForestryCut
        );

        // D51, GiftForestryCutNetProfit

        calcResults.giftForestryCutNetProfit =
            (calcResults.tradeForestryCut / 100.0) * calc.investmentIncomeTax;
        calcResults.giftForestryCutNetProfit = Utils.roundToTwoDec(
            calcResults.giftForestryCutNetProfit
        );

        // D52, fInvestmentIncomeToUtilizeForestryCut
        calcResults.fInvestmentIncomeToUtilizeForestryCut =
            calcResults.tradeForestryCut / 0.6;
        calcResults.fInvestmentIncomeToUtilizeForestryCut = Utils.roundToTwoDec(
            calcResults.fInvestmentIncomeToUtilizeForestryCut
        );

        // Update string representations of results
        resultsStr.currentPrice = calc.currentPrice.toLocaleString() + ' €';
        resultsStr.investmentIncomeTax =
            calc.investmentIncomeTax.toLocaleString() + ' %';
        resultsStr.amountOfGifts =
            calcExtra.amountOfGifts.toLocaleString() + ' kpl';
        resultsStr.tradePercentage = calcExtra.tradePercentage + ' %';
        resultsStr.tradeOtherCosts =
            calcExtra.tradeOtherCosts.toLocaleString() + ' €';
        resultsStr.giftForestryCut =
            calcExtra.giftForestryCut.toLocaleString() + ' €';
        resultsStr.leftOverForestryCut =
            calcExtra.leftOverForestryCut.toLocaleString() + ' €';
        resultsStr.giftTax = calc.giftTax.toLocaleString() + ' €';
        resultsStr.forestryGiftCut =
            calc.forestryGiftCut.toLocaleString() + ' €';
        resultsStr.investmentIncome =
            calc.investmentIncome.toLocaleString() + ' €';
        resultsStr.netProfit = calc.netProfit.toLocaleString() + ' €';
        resultsStr.tradePrice = calcExtra.tradePrice.toLocaleString() + ' €';
        resultsStr.giftTaxAmount =
            calcExtra.giftTaxAmount.toLocaleString() + ' €';
        resultsStr.tradeArea = calcExtra.tradeArea.toLocaleString() + ' ha';
        resultsStr.overallForestryCut =
            calcExtra.overallForestryCut.toLocaleString() + ' €';
        resultsStr.tradeForestryCut =
            calcResults.tradeForestryCut.toLocaleString() + ' €';
        resultsStr.giftForestryCutNetProfit =
            calcResults.giftForestryCutNetProfit.toLocaleString() + ' €';
        resultsStr.fInvestmentIncomeToUtilizeForestryCut =
            calcResults.fInvestmentIncomeToUtilizeForestryCut.toLocaleString() +
            ' €';

        // Update state accordingly
        this.setState({
            calculation: calc,
            extraCalculation: calcExtra,
            giftTaxCalculation: giftTaxCalc,
            calculationResults: calcResults,
            resultsStr: resultsStr,
        });

        this.props.setLiveRegionMessage(
            this.props.locale.ariaCalcResultsGetted
        );
    }

    // ------------------------------------------------------------------------
    // -- Event: Generate PDF from calculation results
    // ------------------------------------------------------------------------
    handleGeneratePDF(event) {
        // Prepare document related data
        let calculation = { ...this.state.calculation };
        let extraCalculation = { ...this.state.extraCalculation };
        let calculationResults = { ...this.state.calculationResults };
        let resultsStr = { ...this.state.resultsStr };
        let estates = [...this.state.estates];
        let doc = new jsPDF('p', 'pt', 'a4');

        // Page dimensions
        let pdf_w = doc.internal.pageSize.width; // jsPDF <= 1.13
        if (pdf_w === undefined) {
            pdf_w = doc.internal.pageSize.getWidth(); // jsPDF >= 1.14
        }
        let pdf_h = doc.internal.pageSize.height; // jsPDF <= 1.13
        if (pdf_h === undefined) {
            pdf_h = doc.internal.pageSize.getHeight(); // jsPDF >= 1.14
        }

        // jsPDF-AutoTable global defaults
        jsPDF.autoTableSetDefaults({
            columnStyles: { id: { fontStyle: 'bold' } },
            headStyles: { fillColor: 0 },
        });

        // doc Font settings
        doc.setFontSize(16);

        // Prepare estate columns
        let columns = [
            {
                title: this.props.locale.calcEstateGridColDesc,
                dataKey: 'description',
            },
            {
                title: this.props.locale.calcEstateGridColMun,
                dataKey: 'municipality',
            },
            {
                title: this.props.locale.calcEstateGridColProf,
                dataKey: 'profitNum',
            },
            { title: this.props.locale.calcEstateGridColArea, dataKey: 'area' },
        ];

        // Prepare estate rows
        estates.push({
            description: '',
            municipality: '',
            profitNum: this.props.locale.calcEstateGridColSum,
            area: calculation.giftEstateAreaSummary,
        });

        // PDF Title text
        doc.setFontSize(20);
        doc.text(this.props.locale.pdfTitle, 16, 58);
        doc.setFontSize(10);
        doc.text(
            this.props.locale.pdfSubtitle1 +
            this.state.year.selectedYear +
            this.props.locale.pdfSubtitle2,
            16,
            78
        );

        // PDF SMK Logo
        let logo = new Image();
        logo.src = this.props.logo;
        doc.addImage(logo, pdf_w - 16 - 150, 25, 150, 50);

        // Current cursor y-position
        let pdf_y = 0;

        // Generate estates table
        doc.setTextColor(22, 125, 63);
        doc.setFontSize(16);
        doc.text(this.props.locale.calcEstateGridTitle, 16, 112);
        doc.autoTable({
            columns,
            body: estates,
            headStyles: {
                fillColor: [22, 125, 63],
            },
            columnStyles: {
                fillColor: [255, 255, 255],
            },
            styles: {
                overflow: 'linebreak',
                fontSize: 10,
                cellPadding: 5,
                halign: 'center',
            },
            margin: { left: 16, right: 16, top: 128 },
            didDrawCell: function (row) {
                pdf_y = row.cursor.y;
            },
            didDrawPage: function (data) { },
        });

        pdf_y += 50;

        // Calculate our y-position after last table
        //pdf_y = 128 + 56 + 22 * estates.length;

        // Add new page if estates list length exceeds one page height
        if (pdf_y > 420) {
            doc.addPage();

            // Re-adjust y because of new page
            pdf_y = 58;
        }
        // Prepare calculation rows
        let calc_rows = [
            {
                text: this.props.locale.calcCalcTaxClass,
                value: this.props.taxClasses[calculation.taxClass - 1].label,
            },
            {
                text: this.props.locale.calcCalcCurrentPrice,
                value: resultsStr.currentPrice,
            },
            {
                text: this.props.locale.calcCalcInvestmentIncomeTax,
                value: resultsStr.investmentIncomeTax,
            },
            {
                text: this.props.locale.calcCalcAmountOfGifts,
                value: resultsStr.amountOfGifts,
            },
            {
                text: this.props.locale.calcCalcTradePercentage,
                value: resultsStr.tradePercentage,
            },
            {
                text: this.props.locale.calcCalcTradeOtherCosts,
                value: resultsStr.tradeOtherCosts,
            },
            {
                text: this.props.locale.calcCalcGiftForestryCut,
                value: resultsStr.giftForestryCut,
            },
            {
                text: this.props.locale.calcCalcLeftOverForestryCut,
                value: resultsStr.leftOverForestryCut,
            },
        ];

        // Generate calculation table
        doc.setTextColor(22, 125, 63);
        doc.setFontSize(16);
        doc.text(this.props.locale.calcCalcGridTitle, 16, pdf_y);

        doc.autoTable({
            columns: [
                { title: 'text', dataKey: 'text' },
                { title: 'value', dataKey: 'value' },
            ],
            body: calc_rows,
            showHead: 'never',
            columnStyles: {
                text: {
                    fillColor: [22, 125, 63],
                    textColor: 255,
                    fontStyle: 'bold',
                    cellWidth: pdf_w * 0.25,
                    halign: 'left',
                },
                value: {
                    halign: 'center',
                    valign: 'middle',
                },
            },
            styles: {
                overflow: 'linebreak',
                fontSize: 10,
                cellPadding: 5,
            },
            startY: pdf_y + 16,
            margin: { left: 16, right: pdf_w * 0.5, top: pdf_y + 500 },
            didDrawPage: function (data) { },
        });

        // Calculate our x-position after last table
        let pdf_x = pdf_w * 0.5 + 16;

        // Prepare results rows
        let resu_rows = [
            {
                text: this.props.locale.calcCalcGiftTax,
                value: resultsStr.giftTax,
            },
            {
                text: this.props.locale.calcCalcforestryGiftCut,
                value: resultsStr.forestryGiftCut,
            },
            {
                text: this.props.locale.calcCalcNetProfit,
                value: resultsStr.netProfit,
            },
            {
                text: this.props.locale.calcCalcInvestmentIncome,
                value: resultsStr.investmentIncome,
            },
            {
                text: this.props.locale.calcCalcTradePrice,
                value: resultsStr.tradePrice,
            },
            {
                text: this.props.locale.calcCalcGiftTaxAmount,
                value: resultsStr.giftTaxAmount,
            },
            {
                text: this.props.locale.calcCalcTradeForestryCut,
                value: resultsStr.tradeForestryCut,
            },
            {
                text: this.props.locale.calcCalcOverallForestryCut,
                value: resultsStr.overallForestryCut,
            },
            {
                text: this.props.locale.calcCalcForestryCutNetProfit,
                value: resultsStr.giftForestryCutNetProfit,
            },
            {
                text: this.props.locale.calcCalcFInvestmentIncomeTUFC,
                value: resultsStr.fInvestmentIncomeToUtilizeForestryCut,
            },
        ];

        // Generate results table
        doc.setTextColor(22, 125, 63);
        doc.setFontSize(16);
        doc.text(this.props.locale.calcResultsGridTitle, pdf_x, pdf_y);

        doc.autoTable({
            columns: [
                { title: 'text', dataKey: 'text' },
                { title: 'value', dataKey: 'value' },
            ],
            body: resu_rows,
            showHead: 'never',
            columnStyles: {
                text: {
                    fillColor: [22, 125, 63],
                    textColor: 255,
                    fontStyle: 'bold',
                    cellWidth: pdf_w * 0.25,
                    halign: 'left',
                },
                value: {
                    halign: 'center',
                    valign: 'middle',
                },
            },
            styles: {
                overflow: 'linebreak',
                fontSize: 10,
                cellPadding: 5,
            },
            startY: pdf_y + 16,
            margin: { left: pdf_x, right: 16 },
            didDrawPage: function (data) { },
        });

        // Footer of the PDF, freedom of responsibility text
        doc.setFontSize(5);
        let text = this.props.locale.footerContent;
        let text_split = doc.splitTextToSize(text, 260);
        doc.text(text_split, 104, pdf_h - 27);

        // Footer of the PDF, timestamp
        doc.setFontSize(8);
        let timestamp = new Date();
        let timestampStr =
            ('0' + timestamp.getDate()).slice(-2) +
            '.' +
            ('0' + (timestamp.getMonth() + 1)).slice(-2) +
            '.' +
            timestamp.getFullYear() +
            ' ' +
            timestamp.getHours() +
            ':' +
            ('0' + timestamp.getMinutes()).slice(-2) +
            ':' +
            ('0' + timestamp.getSeconds()).slice(-2);
        doc.text(timestampStr, 16, pdf_h - 16);

        // Footer of the PDF, logos
        let logoSitowise = new Image();
        logoSitowise.src = this.props.logoSitowise;
        doc.addImage(logoSitowise, pdf_w - 16 - 65, pdf_h - 16 - 12, 65, 12);
        let logoTapio = new Image();
        logoTapio.src = this.props.logoTapio;
        doc.addImage(
            logoTapio,
            pdf_w - 16 - 65 - 16 - 53,
            pdf_h - 16 - 12,
            53,
            14
        );
        doc.addImage(
            logo,
            pdf_w - 16 - 65 - 16 - 53 - 16 - 49,
            pdf_h - 14 - 14,
            55,
            19
        );

        // Save the pdf as a file
        doc.save(this.props.locale.pdfFileName + '.pdf');
    }

    // ------------------------------------------------------------------------
    // -- Func: Append row to estates grid
    // ------------------------------------------------------------------------
    addRowToEstateGrid(desc, mun, num, area) {
        let estates = [...this.state.estates];
        estates.push({
            description: desc,
            municipality: mun,
            profitNum: num,
            area: area,
            validatedArea: true,
            validatedMun: true,
        });

        this.setState({
            estates: estates,
        });
    }

    // ------------------------------------------------------------------------
    // -- Func: Remove row from estates grid
    // ------------------------------------------------------------------------
    remRowFromEstateGrid(index, count) {
        let estates = [...this.state.estates];
        estates.splice(index, count);

        this.setState({
            estates: estates,
        });
    }

    // ------------------------------------------------------------------------
    // -- DOM: Render the municipality dropdown-list
    // ------------------------------------------------------------------------

    getMunicipalityDropdown(index) {
        const yearMunicipalities =
            this.state.year.selectedYear === 2017
                ? this.props.municipalities2017
                : this.state.year.selectedYear === 2018
                ? this.props.municipalities2018
                : this.state.year.selectedYear === 2019
                ? this.props.municipalities2019
                : this.state.year.selectedYear === 2020
                ? this.props.municipalities2020
                : this.state.year.selectedYear === 2021
                ? this.props.municipalities2021
                : this.state.year.selectedYear === 2022
                ? this.props.municipalities2022
                : this.state.year.selectedYear === 2023
                ? this.props.municipalities2023
                : this.state.year.selectedYear === 2024
                ? this.props.municipalities2024
                : null;
        let dropdown = (
            <Select
                id={`estate-grid-municipality-${index}`}
                name="municipality"
                value={
                    this.state.year.selectedYear === '-'
                        ? false
                        : this.state.estates[index]
                }
                placeholder={this.props.locale.municipalityPlaceholder}
                noResultsText={this.props.locale.dropdownNoResults}
                options={yearMunicipalities}
                valueRenderer={function (option) {
                    return option.municipality;
                }.bind(this)}
                onChange={function (value) {
                    if (value !== null) {
                        // Get estates
                        let estates = [...this.state.estates];
                        // Change current estate profit num
                        estates[index].municipality = value.label;
                        estates[index].profitNum = value.value;
                        // Update state
                        this.setState({
                            estates: estates,
                        });
                    }
                }.bind(this)}
                filterOption={(option, inputValue) => {
                    // empty value, return all options
                    if (!inputValue) return true;
                    return option.label
                        .toLowerCase()
                        .startsWith(inputValue.toLowerCase());
                }}
                onMenuOpen={() => {
                    if (yearMunicipalities === null) {
                        this.props.setLiveRegionMessage(
                            this.props.locale.ariaMunicipalityNoResults
                        );
                        return;
                    }
                    this.props.setLiveRegionMessage(
                        this.props.locale.ariaMunicipalityResults.replace(
                            '{count}',
                            yearMunicipalities.length
                        )
                    );
                }}
                onInputChange={(inputValue) => {
                    if (yearMunicipalities === null) {
                        this.props.setLiveRegionMessage(
                            this.props.locale.ariaMunicipalityNoResults
                        );
                        return;
                    }
                    if (!inputValue) {
                        this.props.locale.ariaMunicipalityResults.replace(
                            '{count}',
                            yearMunicipalities.length
                        );
                    }
                    const filteredResults = yearMunicipalities.filter((item) =>
                        item.label
                            .toLowerCase()
                            .startsWith(inputValue.toLowerCase())
                    ).length;

                    if (filteredResults === 0) {
                        this.props.setLiveRegionMessage(
                            this.props.locale.ariaMunicipalityNoResults
                        );
                    } else if (filteredResults === 1) {
                        this.props.setLiveRegionMessage(
                            this.props.locale.ariaMunicipalityResult
                        );
                    } else {
                        this.props.setLiveRegionMessage(
                            this.props.locale.ariaMunicipalityResults.replace(
                                '{count}',
                                filteredResults
                            )
                        );
                    }
                }}
                required
            />
        );

        return dropdown;
    }

    getYearDropdown() {
        const yearOptions = [
            { value: '-', label: '-' },
            { value: 2024, label: 2024 },
            { value: 2023, label: 2023 },
            { value: 2022, label: 2022 },
            { value: 2021, label: 2021 },
            { value: 2020, label: 2020 },
            { value: 2019, label: 2019 },
            { value: 2018, label: 2018 },
            { value: 2017, label: 2017 },
        ];

        let dropdown = (
            <Select
                name="year"
                id="select-year"
                value={this.state.year}
                noResultsText={this.props.locale.dropdownNoResults}
                options={yearOptions}
                valueRenderer={function (option) {
                    return option.selectedYear;
                }.bind(this)}
                onChange={function (value) {
                    if (value !== null) {
                        let selectedYear = this.state.year;
                        this.handleClear();
                        selectedYear.selectedYear = value.value;
                        this.setState({
                            year: selectedYear,
                        });
                    }
                }.bind(this)}
            />
        );

        return dropdown;
    }

    // ------------------------------------------------------------------------
    // -- DOM: Render the estates grid
    // ------------------------------------------------------------------------
    getRowsFromEstateGrid() {
        return this.state.estates.map((row, index) => (
            <>
            <div key={index} className="columns is-mobile">
                <div className="column is-5">
                    <div className='estate-grid-header'>
                        <label htmlFor={`estate-grid-description-${index}`}>
                            {this.props.locale.calcEstateGridColDesc}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcEstateGridColDesc
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttEstateDescription}
                        />
                    </div>
                    <p className="control">
                        <input
                            id={`estate-grid-description-${index}`}
                            className="input"
                            type="text"
                            name={index}
                            value={row.description}
                            onInput={this.handleEstateDescChanged.bind(this)}
                        ></input>
                    </p>
                </div>
                <div className="column is-2">
                    <div className='estate-grid-header'>
                        <label htmlFor={`estate-grid-municipality-${index}`}>
                            {' '}
                            {this.props.locale.calcEstateGridColMun}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcEstateGridColMun
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttEstateMunicipality}
                        />
                        <span>{this.props.locale.requiredTooltip}</span>
                    </div>
                    {this.getMunicipalityDropdown(index)}
                    {!this.state.validation.estateMunicipalities &&
                        !row.validatedMun && (
                            <div className="notification is-danger notification-mun">
                                {this.props.locale.validFailedNotification}
                            </div>
                        )}
                </div>
                <div className="column is-2">
                    <div className='estate-grid-header'>
                        <label htmlFor={`estate-grid-prof-${index}`}>
                            {this.props.locale.calcEstateGridColProf}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcEstateGridColProf
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttEstateProfitNum}
                        />
                    </div>
                    <p className="control">
                        <input
                            id={`estate-grid-prof-${index}`}
                            className="input"
                            type="number"
                            value={row.profitNum}
                            disabled
                        ></input>
                    </p>
                </div>
                <div className="column is-3">
                    <div className='estate-grid-header'>
                        <label htmlFor={`estate-grid-area-${index}`}>
                            {' '}
                            {this.props.locale.calcEstateGridColArea}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcEstateGridColArea
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttEstateArea}
                        />
                        <span>{this.props.locale.requiredTooltip}</span>
                    </div>
                    <p className="control">
                        <input
                            id={`estate-grid-area-${index}`}
                            className="input"
                            type="number"
                            name={index}
                            placeholder={this.props.locale.requiredField}
                            value={row.area}
                            onChange={this.handleEstateAreaChanged.bind(this)}
                            aria-describedby={`estate-grid-area-hint-${index}`}
                            required
                        />
                        <span
                            id={`estate-grid-area-hint-${index}`}
                            class="hint"
                        >
                            {this.props.locale.hintOneDecimal
                                .replace('{example}', '5.5')
                                .replace('{numBetween}', '')
                                .replace('{chars}', maxNumberLength)}
                        </span>
                    </p>

                    {!this.state.validation.estateAreas &&
                        !row.validatedArea && (
                            <div className="notification is-danger">
                                {this.props.locale.validFailedNotification}
                            </div>
                        )}
                </div>
                <div className="column is-1">
                    {index > 0 && (
                        <p className="control mt-50-p">
                            <button
                                className="button is-danger"
                                name={index}
                                onClick={this.handleRemRowFromEstateGrid.bind(
                                    this
                                )}
                            >
                                {this.props.locale.calcEstateGridBtnRemRow}
                            </button>
                        </p>
                    )}
                </div>
            </div>
            {index !== this.state.estates.length - 1 && (
                <div className="divider"></div>
            )}
            </>
        ));
    }

    // ------------------------------------------------------------------------
    // -- DOM: Render the estates grid summary
    // ------------------------------------------------------------------------
    getSummaryFromEstateGrid() {
        return (
            <div className="columns">
                <div className="column is-3 is-offset-6">
                    <label htmlFor="estate-grid-area-summary">
                        {this.props.locale.calcEstateGridColSum}
                    </label>
                    <ToolTip
                        ariaLabel={this.props.locale.ariaTooltip.replace(
                            '{DescribeBy}',
                            this.props.locale.calcEstateGridColSum
                        )}
                        className="icon-right"
                        icon="fa fa-info"
                        text={this.props.locale.ttEstateAreaSum}
                    />
                </div>
                <div className="column is-3">
                    <p className="control">
                        <input
                            id="estate-grid-area-summary"
                            className="input"
                            type="number"
                            value={this.state.calculation.giftEstateAreaSummary}
                            disabled
                        ></input>
                    </p>
                </div>
            </div>
        );
    }

    // ------------------------------------------------------------------------
    // -- DOM: Render the calculation grid
    // ------------------------------------------------------------------------
    getRowsFromCalculationGrid() {
        return (
            <div className="container">
                <div className="heading">
                    <h2 className="title">
                        {this.props.locale.calcCalcGridTitle}
                    </h2>
                </div>

                <div className="columns">
                    <div className="column is-3">
                        <label htmlFor="calculation-grid-tax-class">
                            {this.props.locale.calcCalcTaxClass}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcTaxClass
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttCalcTaxClass}
                        />
                    </div>
                    <div className="column is-3">
                        <Select
                            id="calculation-grid-tax-class"
                            name="taxclass"
                            value={this.state.calculation.taxClass}
                            placeholder={this.props.locale.dropdownPlaceholder}
                            noResultsText={this.props.locale.dropdownNoResults}
                            options={this.props.taxClasses}
                            onChange={function (value) {
                                let calculation = { ...this.state.calculation };
                                calculation.taxClass = value.value;
                                this.setState({
                                    calculation: calculation,
                                });
                            }.bind(this)}
                        />
                    </div>
                    <div className="column is-3">
                        <label htmlFor="calculation-grid-current-price">
                            {' '}
                            {this.props.locale.calcCalcCurrentPrice}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcCurrentPrice
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttCalcCurrentPrice}
                        />
                        <span>{this.props.locale.requiredTooltip}</span>
                    </div>
                    <div className="column is-3">
                        <CalcField
                            id="calculation-grid-current-price"
                            type="number"
                            name="currentprice"
                            ref="currentprice"
                            placeholder={this.props.locale.requiredField}
                            value={this.state.calculation.currentPrice}
                            min="0"
                            roundToTwo={true}
                            locale={this.props.locale}
                            maxNumberLength={maxNumberLength}
                            onInput={function (event) {
                                let calculation = { ...this.state.calculation };
                                calculation.currentPrice = Utils.parseFloatNum(
                                    event.target.value
                                );
                                this.setState({
                                    calculation: calculation,
                                });
                            }.bind(this)}
                            required={true}
                        />
                        {!this.state.validation.currentPrice && (
                            <div className="notification is-danger">
                                {this.props.locale.validFailedNotification}
                            </div>
                        )}
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-3">
                        <label htmlFor="calculation-grid-investment-income-tax">
                            {' '}
                            {this.props.locale.calcCalcInvestmentIncomeTax}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcInvestmentIncomeTax
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttCalcInvestmentIncomeTax}
                        />
                        <span>{this.props.locale.requiredTooltip}</span>
                    </div>
                    <div className="column is-3">
                        <CalcField
                            id="calculation-grid-investment-income-tax"
                            type="number"
                            name="investmentincometax"
                            ref="investmentincometax"
                            placeholder={this.props.locale.requiredField}
                            value={this.state.calculation.investmentIncomeTax}
                            min="30"
                            max="34"
                            locale={this.props.locale}
                            roundToTwo={true}
                            onInput={function (event) {
                                let calculation = { ...this.state.calculation };
                                calculation.investmentIncomeTax =
                                    Utils.parseFloatNum(event.target.value);
                                this.setState({
                                    calculation: calculation,
                                });
                            }.bind(this)}
                            required={true}
                            maxNumberLength={maxNumberLength}
                        />
                        {!this.state.validation.investmentIncomeTax && (
                            <div className="notification is-danger">
                                {this.props.locale.validFailedNotification}
                            </div>
                        )}
                    </div>
                    <div className="column is-3">
                        <label htmlFor="calculation-grid-amount-of-gifts">
                            {' '}
                            {this.props.locale.calcCalcAmountOfGifts}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcAmountOfGifts
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttCalcAmountOfGifts}
                        />
                        <span>{this.props.locale.requiredTooltip}</span>
                    </div>
                    <div className="column is-3">
                        <CalcField
                            id="calculation-grid-amount-of-gifts"
                            type="number"
                            name="amountofgifts"
                            ref="amountofgifts"
                            placeholder={this.props.locale.requiredField}
                            required={true}
                            min="1"
                            locale={this.props.locale}
                            value={this.state.extraCalculation.amountOfGifts}
                            roundToZero={true}
                            onInput={function (event) {
                                let calculation = { ...this.state.calculation };
                                let extraCalculation = {
                                    ...this.state.extraCalculation,
                                };
                                extraCalculation.amountOfGifts =
                                    Utils.parseFloatNum(event.target.value);
                                calculation.giftEstateAreaSummary =
                                    ((calculation.estateAreaSummary /
                                        extraCalculation.amountOfGifts) *
                                        (100.0 -
                                            extraCalculation.tradePercentage)) /
                                    100.0;
                                calculation.giftEstateAreaSummary =
                                    Utils.roundToTwoDec(
                                        calculation.giftEstateAreaSummary
                                    );
                                this.setState({
                                    calculation: calculation,
                                    extraCalculation: extraCalculation,
                                });
                            }.bind(this)}
                            maxNumberLength={maxNumberLength}
                        />
                        {!this.state.validation.amountOfGifts && (
                            <div className="notification is-danger">
                                {this.props.locale.validFailedNotification}
                            </div>
                        )}
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-3">
                        <label htmlFor="calculation-grid-trade-percentage">
                            {' '}
                            {this.props.locale.calcCalcTradePercentage}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcTradePercentage
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttCalcTradePercentage}
                        />
                        <span>{this.props.locale.requiredTooltip}</span>
                    </div>
                    <div className="column is-3">
                        <CalcField
                            id="calculation-grid-trade-percentage"
                            type="number"
                            name="tradepercentage"
                            ref="tradepercentage"
                            placeholder={this.props.locale.requiredField}
                            required={true}
                            value={this.state.extraCalculation.tradePercentage}
                            min="0"
                            max="100"
                            locale={this.props.locale}
                            roundToFour={true}
                            onInput={function (event) {
                                let calculation = { ...this.state.calculation };
                                let extraCalculation = {
                                    ...this.state.extraCalculation,
                                };
                                extraCalculation.tradePercentage =
                                    Utils.roundToFourDec(event.target.value);
                                calculation.giftEstateAreaSummary =
                                    ((calculation.estateAreaSummary /
                                        extraCalculation.amountOfGifts) *
                                        (100.0 -
                                            extraCalculation.tradePercentage)) /
                                    100.0;
                                calculation.giftEstateAreaSummary =
                                    Utils.roundToTwoDec(
                                        calculation.giftEstateAreaSummary
                                    );
                                this.setState({
                                    calculation: calculation,
                                    extraCalculation: extraCalculation,
                                });
                            }.bind(this)}
                            maxNumberLength={maxNumberLength}
                        />
                        {!this.state.validation.tradePercentage && (
                            <div className="notification is-danger">
                                {this.props.locale.validFailedNotification}
                            </div>
                        )}
                    </div>
                    <div className="column is-3">
                        <label htmlFor="calculation-grid-trade-other-costs">
                            {this.props.locale.calcCalcTradeOtherCosts}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcTradeOtherCosts
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttCalcTradeOtherCosts}
                        />
                    </div>
                    <div className="column is-3">
                        <CalcField
                            id="calculation-grid-trade-other-costs"
                            type="number"
                            name="tradeothercosts"
                            ref="tradeothercosts"
                            value={this.state.extraCalculation.tradeOtherCosts}
                            min="0"
                            locale={this.props.locale}
                            roundToTwo={true}
                            onInput={function (event) {
                                let extraCalculation = {
                                    ...this.state.extraCalculation,
                                };
                                extraCalculation.tradeOtherCosts =
                                    Utils.parseFloatNum(event.target.value);
                                this.setState({
                                    extraCalculation: extraCalculation,
                                });
                            }.bind(this)}
                            maxNumberLength={maxNumberLength}
                        />
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-3">
                        <label htmlFor="calculation-grid-gift-foresty-cut">
                            {this.props.locale.calcCalcGiftForestryCut}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcGiftForestryCut
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttCalcGiftForestryCut}
                        />
                    </div>
                    <div className="column is-3">
                        <CalcField
                            id="calculation-grid-gift-foresty-cut"
                            type="number"
                            name="giftforestrycut"
                            ref="giftforestrycut"
                            value={this.state.extraCalculation.giftForestryCut}
                            min="0"
                            locale={this.props.locale}
                            roundToTwo={true}
                            onInput={function (event) {
                                let extraCalculation = {
                                    ...this.state.extraCalculation,
                                };
                                extraCalculation.giftForestryCut =
                                    Utils.parseFloatNum(event.target.value);
                                this.setState({
                                    extraCalculation: extraCalculation,
                                });
                            }.bind(this)}
                            maxNumberLength={maxNumberLength}
                        />
                    </div>
                    <div className="column is-3">
                        <label htmlFor="calculation-grid-left-over-foresty-cut">
                            {this.props.locale.calcCalcLeftOverForestryCut}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcLeftOverForestryCut
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttCalcLeftOverForestryCut}
                        />
                    </div>
                    <div className="column is-3">
                        <CalcField
                            id="calculation-grid-left-over-foresty-cut"
                            type="number"
                            name="leftoverforestrycut"
                            ref="leftoverforestrycut"
                            value={
                                this.state.extraCalculation.leftOverForestryCut
                            }
                            min="0"
                            locale={this.props.locale}
                            roundToTwo={true}
                            onInput={function (event) {
                                let extraCalculation = {
                                    ...this.state.extraCalculation,
                                };
                                extraCalculation.leftOverForestryCut =
                                    Utils.parseFloatNum(event.target.value);
                                this.setState({
                                    extraCalculation: extraCalculation,
                                });
                            }.bind(this)}
                            maxNumberLength={maxNumberLength}
                        />
                    </div>
                </div>

                <div className="block-right">
                    <button
                        className="button is-danger block-right-btn"
                        onClick={this.handleClear.bind(this)}
                    >
                        {this.props.locale.calcCalcGridBtnClear}
                    </button>
                    <button
                        className="button is-success block-right-btn"
                        onClick={this.handleCalculateResults.bind(this)}
                    >
                        {this.props.locale.calcCalcGridBtnExecute}
                    </button>
                </div>
            </div>
        );
    }

    // ------------------------------------------------------------------------
    // -- DOM: Render the results grid
    // ------------------------------------------------------------------------
    getRowsFromResultsGrid() {
        return (
            <div id="resultsGrid" className="container">
                <div className="heading">
                    <h2 className="title">
                        {this.props.locale.calcResultsGridTitle}
                    </h2>
                </div>

                <div className="columns">
                    <div className="column is-3">
                        <label id="result-grid-gift-tax-label" htmlFor="result-grid-gift-tax">
                            {this.props.locale.calcCalcGiftTax}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcGiftTax
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttRsultGiftTax}
                        />
                    </div>
                    <div className="column is-1">
                        <strong className="control">
                            <label className="checkbox field-nowrap checkbox-label">
                                <input
                                    type="checkbox"
                                    ref="checkBox1"
                                    aria-label={this.props.locale.ariaGiftTaxEditChecbox}
                                    value={this.state.calculation.giftTaxEdit}
                                    onClick={function (event) {
                                        console.log(event.target);

                                        let calculation = {
                                            ...this.state.calculation,
                                        };
                                        calculation.giftTaxEdit =
                                            event.target.checked;
                                        this.setState({
                                            calculation: calculation,
                                        });
                                    }.bind(this)}
                                />
                                {this.props.locale.editCheckboxLabel}
                            </label>
                        </strong>
                    </div>
                    <div className="column is-2">
                        {!this.state.calculation.giftTaxEdit && (
                            <p className="control">
                                <input
                                    id="result-grid-gift-tax"
                                    className="input"
                                    type="text"
                                    value={this.state.resultsStr.giftTax}
                                    disabled
                                ></input>
                            </p>
                        )}
                        {this.state.calculation.giftTaxEdit && (
                            <CalcField
                                id="result-grid-gift-tax"
                                type="number"
                                name="gifttax"
                                ref="gifttax"
                                placeholder={this.props.locale.requiredField}
                                value={this.state.calculation.giftTax}
                                roundToTwo={true}
                                locale={this.props.locale}
                                onInput={function (event) {
                                    let calculation = {
                                        ...this.state.calculation,
                                    };
                                    let extraCalculation = {
                                        ...this.state.extraCalculation,
                                    };
                                    let validation = {
                                        ...this.state.validation,
                                    };
                                    calculation.giftTax = Utils.parseFloatNum(
                                        event.target.value
                                    );
                                    validation.validated = false;
                                    this.setState({
                                        calculation: calculation,
                                        extraCalculation: extraCalculation,
                                        validation: validation,
                                    });
                                }.bind(this)}
                                maxNumberLength={maxNumberLength}
                            />
                        )}
                    </div>

                    <div className="column is-3">
                        <label htmlFor="result-grid-trade-price">
                            {this.props.locale.calcCalcTradePrice}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcTradePrice
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttRsultTradePrice}
                        />
                    </div>
                    <div className="column is-3">
                        <p className="control">
                            <input
                                id="result-grid-trade-price"
                                className="input"
                                type="text"
                                value={this.state.resultsStr.tradePrice}
                                disabled
                            ></input>
                        </p>
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-3">
                        <label htmlFor="result-grid-foresty-gift-cut">
                            {this.props.locale.calcCalcforestryGiftCut}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcforestryGiftCut
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttRsultForestryGiftCut}
                        />
                    </div>
                    <div className="column is-3">
                        <p className="control">
                            <input
                                id="result-grid-foresty-gift-cut"
                                className="input"
                                type="text"
                                value={this.state.resultsStr.forestryGiftCut}
                                disabled
                            ></input>
                        </p>
                    </div>

                    <div className="column is-3">
                        <label htmlFor="result-grid-gift-tax-amount">
                            {this.props.locale.calcCalcGiftTaxAmount}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcGiftTaxAmount
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttRsultGiftTaxAmount}
                        />
                    </div>
                    <div className="column is-3">
                        <p className="control">
                            <input
                                id="result-grid-gift-tax-amount"
                                className="input"
                                type="text"
                                value={this.state.resultsStr.giftTaxAmount}
                                disabled
                            ></input>
                        </p>
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-3">
                        <label htmlFor="result-grid-net-profit">
                            {this.props.locale.calcCalcNetProfit}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcNetProfit
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttRsultNetProfit}
                        />
                    </div>
                    <div className="column is-3">
                        <p className="control">
                            <input
                                id="result-grid-net-profit"
                                className="input"
                                type="text"
                                value={this.state.resultsStr.netProfit}
                                disabled
                            ></input>
                        </p>
                    </div>

                    <div className="column is-3">
                        <label
                            htmlFor="result-grid-trade-foresty-cut"
                            className="text-long"
                        >
                            {this.props.locale.calcCalcTradeForestryCut}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcTradeForestryCut
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttRsultTradeForestryCut}
                        />
                    </div>
                    <div className="column is-3">
                        <p className="control">
                            <input
                                id="result-grid-trade-foresty-cut"
                                className="input"
                                type="text"
                                value={this.state.resultsStr.tradeForestryCut}
                                disabled
                            ></input>
                        </p>
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-3">
                        <label htmlFor="result-grid-investment-income">
                            {this.props.locale.calcCalcInvestmentIncome}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcInvestmentIncome
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttRsultInvestmentIncome}
                        />
                    </div>
                    <div className="column is-3">
                        <p className="control">
                            <input
                                id="result-grid-investment-income"
                                className="input"
                                type="text"
                                value={this.state.resultsStr.investmentIncome}
                                disabled
                            ></input>
                        </p>
                    </div>

                    <div className="column is-3">
                        <label id="result-grid-overall-foresty-cut-label" htmlFor="result-grid-overall-foresty-cut">
                            {this.props.locale.calcCalcOverallForestryCut}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcOverallForestryCut
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={this.props.locale.ttRsultOverallForestryCut}
                        />
                    </div>
                    <div className="column is-1">
                        <strong className="control">
                            <label className="checkbox field-nowrap checkbox-label">
                                <input
                                    type="checkbox"
                                    ref="checkBox2"
                                    aria-describedby='result-grid-overall-foresty-cut-label'
                                    value={
                                        this.state.extraCalculation
                                            .overallForestryCutEdit
                                    }
                                    onClick={function (event) {
                                        let extraCalculation = {
                                            ...this.state.extraCalculation,
                                        };
                                        extraCalculation.overallForestryCutEdit =
                                            event.target.checked;
                                        this.setState({
                                            extraCalculation: extraCalculation,
                                        });
                                    }.bind(this)}
                                />
                                {this.props.locale.editCheckboxLabel}
                            </label>
                        </strong>
                    </div>
                    <div className="column is-2">
                        {!this.state.extraCalculation
                            .overallForestryCutEdit && (
                                <p className="control">
                                    <input
                                        id="result-grid-overall-foresty-cut"
                                        className="input"
                                        type="text"
                                        value={
                                            this.state.resultsStr.overallForestryCut
                                        }
                                        disabled
                                    ></input>
                                </p>
                            )}
                        {this.state.extraCalculation.overallForestryCutEdit && (
                            <CalcField
                                id="result-grid-overall-foresty-cut"
                                type="number"
                                name="overallforestrycut"
                                ref="overallforestrycut"
                                placeholder={this.props.locale.requiredField}
                                value={
                                    this.state.extraCalculation
                                        .overallForestryCut
                                }
                                roundToTwo={true}
                                locale={this.props.locale}
                                onInput={function (event) {
                                    let calculation = {
                                        ...this.state.calculation,
                                    };
                                    let extraCalculation = {
                                        ...this.state.extraCalculation,
                                    };
                                    let validation = {
                                        ...this.state.validation,
                                    };
                                    extraCalculation.overallForestryCut =
                                        Utils.parseFloatNum(event.target.value);
                                    validation.validated = false;
                                    this.setState({
                                        calculation: calculation,
                                        extraCalculation: extraCalculation,
                                        validation: validation,
                                    });
                                }.bind(this)}
                                maxNumberLength={maxNumberLength}
                            />
                        )}
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-6"></div>
                    <div className="column is-3">
                        <label
                            htmlFor="result-grid-gift-foresty-cut-net"
                            className="text-long"
                        >
                            {this.props.locale.calcCalcForestryCutNetProfit}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcForestryCutNetProfit
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={
                                this.props.locale
                                    .ttRsultGiftForestryCutNetProfit
                            }
                        />
                    </div>
                    <div className="column is-3">
                        <p className="control">
                            <input
                                id="result-grid-gift-foresty-cut-net"
                                className="input"
                                type="text"
                                value={
                                    this.state.resultsStr
                                        .giftForestryCutNetProfit
                                }
                                disabled
                            ></input>
                        </p>
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-6"></div>
                    <div className="column is-3">
                        <label
                            htmlFor="result-grid-investment-income-TUFC"
                            className="text-long"
                        >
                            {this.props.locale.calcCalcFInvestmentIncomeTUFC}
                        </label>
                        <ToolTip
                            ariaLabel={this.props.locale.ariaTooltip.replace(
                                '{DescribeBy}',
                                this.props.locale.calcCalcFInvestmentIncomeTUFC
                            )}
                            className="icon-right"
                            icon="fa fa-info"
                            text={
                                this.props.locale
                                    .ttRsultFInvestmentIncomeToUtilizeForestryCut
                            }
                        />
                    </div>
                    <div className="column is-3">
                        <p className="control">
                            <input
                                id="result-grid-investment-income-TUFC"
                                className="input"
                                type="text"
                                value={
                                    this.state.resultsStr
                                        .fInvestmentIncomeToUtilizeForestryCut
                                }
                                disabled
                            ></input>
                        </p>
                    </div>
                </div>

                <div className="block-right">
                    <button
                        className="button is-success block-right-btn"
                        onClick={this.handleGeneratePDF.bind(this)}
                        disabled={!this.state.validation.validated}
                    >
                        {this.props.locale.calcResultsGridBtnPdf}
                    </button>
                </div>
            </div>
        );
    }

    // ------------------------------------------------------------------------
    // -- DOM: Component render function
    // ------------------------------------------------------------------------
    render() {
        return (
            <section className="section" id="calculator">
                <div id="#estates" className="container">
                    <div className="heading">
                        <h2 className="title">
                            {this.props.locale.calcEstateGridTitle}
                        </h2>
                    </div>
                    <div className="columns is-mobile">
                        <div className="column is-3">
                            <div className="padding">
                                <label htmlFor="select-year">
                                    {this.props.locale.year}
                                </label>
                                <ToolTip
                                    ariaLabel={this.props.locale.ariaTooltip.replace(
                                        '{DescribeBy}',
                                        this.props.locale.year
                                    )}
                                    className="icon-right"
                                    icon="fa fa-info"
                                    text={this.props.locale.ttYearDescription}
                                />
                                <span>{this.props.locale.requiredTooltip}</span>
                            </div>
                            {this.getYearDropdown()}
                            {!this.state.validation.year && (
                                <div className="notification is-danger notification-mun">
                                    {this.props.locale.validFailedNotification}
                                </div>
                            )}
                        </div>
                    </div>
                    {this.getRowsFromEstateGrid()}
                    <div className="block-right">
                        <button
                            className="button is-success block-right-btn"
                            onClick={this.handleAddRowToEstateGrid.bind(this)}
                        >
                            {this.props.locale.calcEstateGridBtnAddRow}
                        </button>
                    </div>
                    <hr />
                    {this.getSummaryFromEstateGrid()}
                </div>
                {this.getRowsFromCalculationGrid()}
                {this.getRowsFromResultsGrid()}
            </section>
        );
    }
}

export default Calculator;
