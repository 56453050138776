// ESLint rule settings
/* eslint-disable no-extend-native */

// ------------------------------------------------------------------------
// -- Func: Native function implementation of String.prototype.endsWith
// ------------------------------------------------------------------------
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (searchString, position) {
    var subjectString = this.toString();
    if (
      typeof position !== "number" ||
      !isFinite(position) ||
      Math.floor(position) !== position ||
      position > subjectString.length
    ) {
      position = subjectString.length;
    }
    position -= searchString.length;
    var lastIndex = subjectString.lastIndexOf(searchString, position);
    return lastIndex !== -1 && lastIndex === position;
  };
}

// ------------------------------------------------------------------------
// -- Func: Round input number to 4 decimal places
// ------------------------------------------------------------------------
var roundToFourDec = function (value) {
    return Math.floor(parseFloat(value) * 10000.0) / 10000.0;
};

// ------------------------------------------------------------------------
// -- Func: Round input number to 2 decimal places
// ------------------------------------------------------------------------
var roundToTwoDec = function (value) {
    return Math.floor(parseFloat(value) * 100.0) / 100.0;
};

// ------------------------------------------------------------------------
// -- Func: Round input number to 1 decimal places
// ------------------------------------------------------------------------
var roundToOneDec = function (value) {
    var calcValue = value;
    const decimals = value.split('.');

    if (decimals.length > 2) {
        calcValue = decimals.slice(0, 2).join('.');
    }
    return Math.floor(calcValue * 10.0) / 10.0;
};

// ------------------------------------------------------------------------
// -- Func: Round input number to 0 decimal places
// ------------------------------------------------------------------------
var roundToZeroDec = function (value) {
    return Math.floor(parseFloat(value));
};

// ------------------------------------------------------------------------
// -- Func: Check if input value is a number or not
// ------------------------------------------------------------------------
var isNumeric = function (value) {
  return (
    !isNaN(parseFloat(value)) &&
    isFinite(value) &&
    !value.endsWith(",") &&
    !value.endsWith(".")
  );
};

// ------------------------------------------------------------------------
// -- Func: Check if input value is valid
// ------------------------------------------------------------------------
var isValidInputNumber = function (value) {
  return (
    isNumeric(value) ||
    value.endsWith(",") ||
    value.endsWith(".") ||
    value === ""
  );
};

// ------------------------------------------------------------------------
// -- Func: Parse floating point number from string
// ------------------------------------------------------------------------
var parseFloatNum = function (value) {
  return value !== "" ? parseFloat(value) : value;
};

// ------------------------------------------------------------------------
// -- Func: Validate a numeric field for the calculator
// ------------------------------------------------------------------------
var validateNum = function (value) {
  return !value && value !== 0;
};

module.exports = {
  roundToFourDec,
  roundToTwoDec,
  roundToOneDec,
  roundToZeroDec,
  isNumeric,
  isValidInputNumber,
  parseFloatNum,
  validateNum,
};
