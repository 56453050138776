// React imports
import React, { Component } from 'react';

// App imports
import Utils from './utils';

class CalcField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
        };
    }

    onInput(event) {
        event.preventDefault();

        let value = event.target.value;
        if(this.props.roundToZero) {
            if (!Number.isInteger(value)) {
                value = Math.floor(value);
            }
        }

        if (value.length > this.props.maxNumberLength || 10) {
            value = (value+'').slice(0, this.props.maxNumberLength || 10)
        }

        // Update state
        this.setState({
            value: value,
        });

        if (this.props.onInput) this.props.onInput({ ...event });
    }

    onBlur(event) {
        event.preventDefault();

        let value = event.target.value;

        // Replace , with . if number field
        if (this.props.type === 'number' && Utils.isNumeric(value) === true)
            value = value.replace(/,/g, '.');

        if (this.props.type === 'number') {
            if (value === '') value = this.props.min;

            value = parseFloat(value);

            if (this.props.min && !this.props.max)
                value = Math.max(value, this.props.min);

            if (this.props.max && !this.props.min)
                value = Math.min(value, this.props.max);

            if (this.props.min && this.props.max)
                value = Math.min(
                    Math.max(value, this.props.min),
                    this.props.max
                );

            if (this.props.roundToZero === true)
                value = Utils.roundToZeroDec(value);

            if (this.props.roundToOne === true)
                value = Utils.roundToOneDec(value);

            if (this.props.roundToTwo === true)
                value = Utils.roundToTwoDec(value);

            if (this.props.roundToFour === true)
                value = Utils.roundToFourDec(value);
        } else if (
            this.props.type === 'number' &&
            Utils.isNumeric(value) === false
        ) {
            value = '';
        }

        this.setState({
            value: value,
        });

        event.target.value = value;

        if (this.props.onInput) this.props.onInput({ ...event });
    }

    getHintText() {
        if (this.props.roundToZero === true)
            return this.props.locale.hintZeroDecimal
                .replace('{example}', this.props.max ? this.props.max - 1 : '5')
                .replace(
                    '{numBetween} ',
                    this.props.min && this.props.max
                        ? ' ' + this.props.min + '-' + this.props.max
                        : ' '
                )
                .replace('{chars}', this.props.maxNumberLength);

        if (this.props.roundToOne === true)
            return this.props.locale.hintOneDecimal
                .replace(
                    '{example}',
                    this.props.max ? this.props.max - 1 + '.5' : '5.5'
                )
                .replace(
                    '{numBetween}',
                    ' ' + this.props.min && this.props.max
                        ? ' ' + this.props.min + '-' + this.props.max
                        : ''
                )
                .replace('{chars}', this.props.maxNumberLength);

        if (this.props.roundToTwo === true)
            return this.props.locale.hintTwoDecimal
                .replace(
                    '{example}',
                    this.props.max ? this.props.max - 1 + '.55' : '5.55'
                )
                .replace(
                    '{numBetween}',
                    this.props.min && this.props.max
                        ? ' ' + this.props.min + ' - ' + this.props.max
                        : ''
                )
                .replace('{chars}', this.props.maxNumberLength);

        if (this.props.roundToFour === true)
            return this.props.locale.hintFourDecimal
                .replace(
                    '{example}',
                    ' ' + this.props.max
                        ? this.props.max - 1 + '.5555'
                        : '5.5555'
                )
                .replace(
                    '{numBetween}',
                    this.props.min && this.props.max
                        ? ' ' + this.props.min + ' - ' + this.props.max
                        : ''
                )
                .replace('{chars}', this.props.maxNumberLength);
    }

    render() {
        return (
            <p className="control">
                <input
                    className="input"
                    type={this.props.type || 'text'}
                    id={this.props.id}
                    name={this.props.name}
                    value={this.state.value}
                    min={this.props.min}
                    max={this.props.max}
                    onChange={() => {}}
                    stpe={this.props.roundToZero ? 1 : null}
                    onInput={this.onInput.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    placeholder={this.props.placeholder}
                    required={!!this.props.required}
                    aria-describedby={
                        this.props.locale ? `${this.props.id}-hint` : ''
                    }
                />
                {this.props.locale && (
                    <span id={`${this.props.id}-hint`} class="hint">
                        {this.getHintText()}
                    </span>
                )}
            </p>
        );
    }
}

export default CalcField;
