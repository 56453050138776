// React imports
import React, { Component } from 'react';

class ToolTip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTooltipVisible: false,
      icon: this.props.icon,
      text: this.props.text
    };
    this.isKeyListenerAttached = false;
  };

  showTooltip = () => {
    this.setState({ isTooltipVisible: true });
  };

  hideTooltip = () => {
    this.setState({ isTooltipVisible: false });
  };

  handleToolTipVisibility = (event) => {
    if (event.key === ' ' || event.key === 'Spacebar') { // Older browsers may use "Spacebar"
      event.preventDefault(); // Prevent page scroll on Spacebar
      this.showTooltip();
    // WCAG 2.1 Guideline 1.4.13 (Content on Hover or Focus) requires that tooltips be dismissible with key press such as the Escape key
    } else if (event.key === 'Escape') {
      this.hideTooltip();
    }
  }

  render() {
    const { isTooltipVisible } = this.state;
    const tooltipId = `tooltip-id-${Math.random().toString(36)}`;
    return (
      <div className={(!this.props.customClass) ? 'tooltip' : this.props.customClass}>
        <button
          className='unstyledButton'
          aria-describedby={tooltipId}
          onMouseEnter={this.showTooltip}
          onMouseLeave={this.hideTooltip}
          onBlur={this.hideTooltip}
          onKeyDown={this.handleToolTipVisibility}
          onClick={this.showTooltip}
          aria-label={this.props.ariaLabel}
        >
          <span className="icon">
            <i className={this.props.icon}></i>
          </span>
        </button>
        {isTooltipVisible && (
          <span id={tooltipId} onMouseEnter={this.showTooltip} onMouseLeave={this.hideTooltip} className="tooltiptext" role="tooltip">{this.props.text}</span>
        )}
      </div>
    );
  };
}

export default ToolTip;